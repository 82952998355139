<template>
  <div class="model-creation">
    <Card class="main mb-1">
      <template v-slot:header>
        <div class="grid formgrid p-3 p-fluid">
          <div class="col-4 lg:col-2 col-offset-8 lg:col-offset-10">
            <Button :label="t('autocbm.projects.form.label.new')" icon="pi pi-plus" iconPos="right" @click="createProject"/>
          </div>
        </div>
      </template>
      <template v-slot:content>
        <DataTable :value="data.projectsValues" dataKey="projectId" stripedRows :paginator="true" :rows="data.rows" :totalRecords="data.projectsTotalCount"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                   :currentPageReportTemplate="t('autocbm.projects.form.pagination')">
          <!--          <Column field="projectId" :header="t('autocbm.projects.form.label.ownerId') + ' / ' + t('autocbm.projects.form.label.projectId')"-->
          <!--                  style="max-width:20rem">-->
          <!--            <template v-if="data.projectsLoading" #body>-->
          <!--              <Skeleton height="1.25rem" width="10rem" />-->
          <!--            </template>-->
          <!--            <template v-else #body="{ data: project }">-->
          <!--              {{ project.userId }}/{{ project.projectId }}-->
          <!--            </template>-->
          <!--          </Column>-->
          <Column field="name" :header="t('autocbm.projects.form.label.name')">
            <template v-if="data.projectsLoading" #body>
              <Skeleton height="1.25rem" width="10rem"/>
            </template>
          </Column>
          <Column field="status" :header="t('autocbm.projects.form.label.status')">
            <template v-if="data.projectsLoading" #body>
              <Skeleton height="1.25rem" width="10rem"/>
            </template>
            <template v-else #body="{ data: project }">
              <Tag v-if="project.status === 'draft'" severity="danger" :value="t('autocbm.projects.form.label.status_' + project.status)"/>
              <Tag v-if="project.status === 'training_started'" severity="warning" :value="t('autocbm.projects.form.label.status_' + project.status)"/>
              <Tag v-if="project.status === 'training_finished'" severity="info" :value="t('autocbm.projects.form.label.status_' + project.status)"/>
              <Tag v-if="project.status === 'project_finished'" severity="success" :value="t('autocbm.projects.form.label.status_' + project.status)"/>
            </template>
          </Column>
          <Column field="createdAt" :header="t('autocbm.projects.form.label.createdAt')">
            <template v-if="data.projectsLoading" #body>
              <Skeleton height="1.25rem" width="10rem"/>
            </template>
            <template v-else #body="{ data: project }">
              {{ displayTimestamp(project.createdAt) }}
            </template>
          </Column>
          <Column field="createdAt" :header="t('autocbm.projects.form.label.updatedAt')">
            <template v-if="data.projectsLoading" #body>
              <Skeleton height="1.25rem" width="10rem"/>
            </template>
            <template v-else #body="{ data: project }">
              {{ displayTimestamp(project.updatedAt) }}
            </template>
          </Column>
          <Column>
            <template #body="{ data: project }">
              <Button type="button" icon="pi pi-pencil" class="p-button-text" @click="loadProject(project.projectId)"
                      :loading="data.projectOpenLoading && project.projectId === data.projectOpeningId"/>
              <Button type="button" icon="pi pi-trash" class="p-button-text p-button-danger ml-2" @click="deleteProject(project.projectId, project.name)"/>
            </template>
          </Column>
          <template #paginatorstart></template>
          <template #paginatorend>
            <Button type="button" icon="pi pi-refresh" class="p-button-text" @click="search"/>
          </template>
        </DataTable>
      </template>
    </Card>


    <Card class="main mb-1">
      <template v-slot:content>

        <DataTable :value="data.values" dataKey="modelId" stripedRows
                   :paginator="true" :rows="20" :rowsPerPageOptions="rowsPerPageOptions"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :currentPageReportTemplate="t('autocbm.projects.management.evaluation.form.pagination')">
          <Column field="projectName" :header="'Projektname'">
            <template v-if="loading" #body>
              <Skeleton height="1.25rem" width="10rem"/>
            </template>
          </Column>
          <Column field="modelName" :header="t('autocbm.projects.management.evaluation.form.label.modelName')">
            <template v-if="loading" #body>
              <Skeleton height="1.25rem" width="10rem"/>
            </template>
          </Column>
          <Column field="algorithmName" :header="t('autocbm.projects.management.evaluation.form.label.algorithmName')">
            <template v-if="loading" #body>
              <Skeleton height="1.25rem" width="10rem"/>
            </template>
          </Column>
          <Column field="createdAt" :header="t('autocbm.projects.management.evaluation.form.label.createdAt')">
            <template v-if="loading" #body>
              <Skeleton height="1.25rem" width="10rem"/>
            </template>
            <template v-else #body="{ data: value }">
              {{ displayTimestamp(value.createdAt) }}
            </template>
          </Column>
          <Column field="precision" :header="t('autocbm.projects.management.evaluation.form.label.precision')">
            <template v-if="loading" #body>
              <Skeleton height="1.25rem" width="10rem"/>
            </template>
            <template v-else #body="{ data: value }">
              {{ Number(value.precision).toFixed(2) }} %
            </template>
          </Column>
          <Column field="recall" :header="t('autocbm.projects.management.evaluation.form.label.recall')">
            <template v-if="loading" #body>
              <Skeleton height="1.25rem" width="10rem"/>
            </template>
            <template v-else #body="{ data: value }">
              {{ Number(value.recall).toFixed(4) }}
            </template>
          </Column>
          <Column field="fScore" :header="t('autocbm.projects.management.evaluation.form.label.fScore')">
            <template v-if="loading" #body>
              <Skeleton height="1.25rem" width="10rem"/>
            </template>
            <template v-else #body="{ data: value }">
              {{ Number(value.fScore).toFixed(4)}}
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script>
import {reactive, computed, onMounted} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import {useConfirm} from 'primevue/useconfirm'
import {useMessage} from '@/dockone/app/message'
import {DateTime} from 'luxon'

export default {
  name: 'ProjectsManagement',
  setup() {
    const store = useStore()
    const router = useRouter()
    const {t} = useI18n()
    const confirm = useConfirm()
    const message = useMessage()

    const data = reactive({
      query: computed({
        get: () => store.getters['autocbm/projects/query'],
        set: value => store.dispatch('autocbm/projects/setQuery', value)
      }),
      page: computed({
        get: () => store.getters['autocbm/projects/page'],
        set: value => store.dispatch('autocbm/projects/setPage', value)
      }),
      rows: computed({
        get: () => store.getters['autocbm/projects/rows'],
        set: value => store.dispatch('autocbm/projects/setRows', value)
      }),
      projects: computed(() => store.getters['autocbm/projects/data']),
      projectsValues: computed(() => {
        if (data.projects) {
          return data.projects
        } else if (data.projectsLoading) {
          return new Array(data.rows)
        } else {
          return null
        }
      }),
      projectsCount: computed(() => store.getters['autocbm/projects/dataCount']),
      projectsTotalCount: computed(() => store.getters['autocbm/projects/dataTotalCount']),
      projectsLoading: false,
      projectCreationLoading: false,
      projectOpenLoading: false,
      projectOpeningId: null,
      values: computed(() => store.getters['autocbm/projects/algorithmResults'])
    })

    const actions = {
      search() {
        data.projectsLoading = true
        return store.dispatch('autocbm/projects/search')
            .catch(() => message.error(t('autocbm.projects.action.search.error.summary'), t('autocbm.projects.action.search.error.detail')))
            .finally(() => data.projectsLoading = false)
      },
      createProject() {
        data.projectCreationLoading = true
        return store.dispatch('autocbm/project/create')
            .then(projectId => {
              router.push({name: 'project_management', params: {id: projectId}})
            })
            .catch(() => message.error(t('autocbm.projects.action.create.error.summary'), t('autocbm.projects.action.create.error.detail')))
            .finally(() => data.projectCreationLoading = false)
      },
      loadProject(projectId) {
        data.projectOpenLoading = true
        data.projectOpeningId = projectId
        return store.dispatch('autocbm/project/load', projectId)
            .then(projectId => {
              router.push({name: 'project_management', params: {id: projectId}})
            })
            .catch(() => message.error(t('autocbm.projects.action.load.error.summary'), t('autocbm.projects.action.load.error.detail', {id: projectId})))
            .finally(() => {
              data.projectOpenLoading = false
              data.projectOpeningId = null
            })
      },
      deleteProject(projectId, name) {
        confirm.require({
          header: t('autocbm.projects.action.delete.head'),
          message: t('autocbm.projects.action.delete.message', {id: name || projectId}),
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: t('autocbm.projects.action.delete.accept'),
          acceptClass: 'p-button-danger',
          rejectLabel: t('autocbm.projects.action.delete.reject'),
          accept: () => {
            data.projectsLoading = true
            store.dispatch('autocbm/projects/delete', projectId)
                .catch(() => message.error(t('autocbm.projects.action.delete.error.summary'),
                    t('autocbm.projects.action.delete.error.detail', {id: name || projectId})))
                .finally(() => data.projectsLoading = false)
          }
        })
      },
      displayTimestamp(timestamp) {
        return DateTime.fromISO(timestamp, {zone: 'UTC'}).toLocal().toLocaleString(DateTime.DATETIME_MED)
      }
    }

    onMounted(() => {
      actions.search()
    })

    return {t, data, ...actions}
  }
}
</script>
